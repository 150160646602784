.track {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
  }
}

.art {
  width: 100px;
  height: 100px;
  display: block;
}
