@import url(https://fonts.googleapis.com/css2?family=Muli:wght@400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  align-items: flex-start;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 479px) {
  .App-margins {
    margin-left: 16px;
    margin-right: 16px;
  }
  .AppHeader-padding {
    padding-left: 16px;
    padding-right: 16px;
  }
  .AppHero-margins {
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media screen and (min-width: 480px) and (max-width: 1023px) {
  .App-margins {
    margin-left: 64px;
    margin-right: 64px;
  }
  .AppHeader-padding {
    padding-left: 64px;
    padding-right: 64px;
  }
  .AppHero-margins {
    margin-left: -64px;
    margin-right: -64px;
  }
}

@media screen and (min-width: 1024px) {
  .App-margins {
    margin-left: 224px;
    margin-right: 224px;
  }
  .AppHeader-padding {
    padding-left: 224px;
    padding-right: 224px;
  }
  .AppHero-margins {
    margin-left: -224px;
    margin-right: -224px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  background-color: #282c34;
  color: #eee;
}

.hidden {
  display: none;
}

/** Buttons **/

.btn {
  background-color: transparent;
  border-radius: 2em;
  border: 1px solid #90a7ee;
  color: #90a7ee;
  cursor: pointer;
  padding: 0.7em 1.5em;
  transition: all 0.25s ease;
  text-decoration: none;
  font-weight: 700;
}

.btn:hover {
  background: #90a7ee;
  color: #333;
}

.btn--login {
  margin: 0 auto;
}

/** Background **/

.background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  -webkit-filter: blur(8em) opacity(0.6);
          filter: blur(8em) opacity(0.6);
  position: absolute;
}

.main-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  width: 80%;
  z-index: 1;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.main-container {
  flex: 1 1;
}

/* Images */
.art {
  display: block;
  cursor: pointer;
}

/* Font family */

@font-face {
  font-family: 'CentraNo2-Book';
  src: local('CentraNo2-Book'), url(/static/media/CentraNo2-Book.bc3e3a52.otf) format('otf');
}

@font-face {
  font-family: 'CentraNo2-Medium';
  src: local('CentraNo2-Medium'), url(/static/media/CentraNo2-Medium.b49c1191.otf) format('otf');
}

/** Now Playing **/

.now-playing__name {
  font-size: 1.5em;
  margin-bottom: 0.2em;
}

.now-playing__artist {
  margin-bottom: 1em;
}

.now-playing__status {
  margin-bottom: 1em;
}

.now-playing__img {
  float: left;
  margin-right: 10px;
  text-align: right;
  width: 45%;
}

.now-playing__img img {
  max-width: 80vmin;
  width: 100%;
}

.now-playing__side {
  margin-left: 5%;
  width: 45%;
}

/** Progress **/

.progress {
  border: 1px solid #eee;
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
}

.progress__bar {
  background-color: #eee;
  height: 4px;
}

.track{display:flex;flex-direction:row;width:100%;margin-bottom:20px}.track__content{display:flex;flex-direction:column;align-items:flex-start;flex-grow:1}.art{width:100px;height:100px;display:block}

