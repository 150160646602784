@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;500;600;700;800&display=swap');

::-webkit-scrollbar {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  align-items: flex-start;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 479px) {
  .App-margins {
    margin-left: 16px;
    margin-right: 16px;
  }
  .AppHeader-padding {
    padding-left: 16px;
    padding-right: 16px;
  }
  .AppHero-margins {
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media screen and (min-width: 480px) and (max-width: 1023px) {
  .App-margins {
    margin-left: 64px;
    margin-right: 64px;
  }
  .AppHeader-padding {
    padding-left: 64px;
    padding-right: 64px;
  }
  .AppHero-margins {
    margin-left: -64px;
    margin-right: -64px;
  }
}

@media screen and (min-width: 1024px) {
  .App-margins {
    margin-left: 224px;
    margin-right: 224px;
  }
  .AppHeader-padding {
    padding-left: 224px;
    padding-right: 224px;
  }
  .AppHero-margins {
    margin-left: -224px;
    margin-right: -224px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  background-color: #282c34;
  color: #eee;
}

.hidden {
  display: none;
}

/** Buttons **/

.btn {
  background-color: transparent;
  border-radius: 2em;
  border: 1px solid #90a7ee;
  color: #90a7ee;
  cursor: pointer;
  padding: 0.7em 1.5em;
  transition: all 0.25s ease;
  text-decoration: none;
  font-weight: 700;
}

.btn:hover {
  background: #90a7ee;
  color: #333;
}

.btn--login {
  margin: 0 auto;
}

/** Background **/

.background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  filter: blur(8em) opacity(0.6);
  position: absolute;
}

.main-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  width: 80%;
  z-index: 1;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.main-container {
  flex: 1;
}

/* Images */
.art {
  display: block;
  cursor: pointer;
}

/* Font family */

@font-face {
  font-family: 'CentraNo2-Book';
  src: local('CentraNo2-Book'), url('./fonts/CentraNo2-Book.otf') format('otf');
}

@font-face {
  font-family: 'CentraNo2-Medium';
  src: local('CentraNo2-Medium'), url('./fonts/CentraNo2-Medium.otf') format('otf');
}
